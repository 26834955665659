import { CubeGuide } from 'c2-llm-plugin';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { i18nContent } from './../../i18nContent';
import store from './../../utils/configureStore';
import { flattenMessages, getLocale } from './../../utils/i18nUtils';
import AppRoutes from './AppRoutes';

function App() {
  const locale = getLocale();

  return (
    <IntlProvider
      locale={locale}
      messages={flattenMessages(i18nContent[locale] ? i18nContent[locale] : i18nContent['en-US'])}
    >
      <Provider store={store}>
        <CubeGuide license="PM" />

        <div className="rbac-app">
          <AppRoutes />
        </div>
      </Provider>
    </IntlProvider>
  );
}

export default React.memo(App);
